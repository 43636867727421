<template>
<div class="d-flex flex-row justify-content-between px-5">
	<div class="d-none d-md-flex auth-highlights-container flex-column justify-content-center mr-5">
		<h1 class="h1 mb-4 pb-4 border-bottom" style="border-color: var(--color-700) !important;">
			Get started with Amino
		</h1>
        <div class="d-flex flex-row mb-5">
			<div>
				<span class="material-icons-outlined text-primary">check_circle</span>
			</div>
			<div class="ml-3">
				<div class="h3 mb-2">
                    Customize any website with CSS
                </div>
                <div class="text-muted">
                    Tinker with the web by creating your own website themes and skins with CSS
                </div>
			</div>
		</div>
		<div class="d-flex flex-row mb-5">
			<div>
				<span class="material-icons-outlined text-primary">check_circle</span>
			</div>
			<div class="ml-3">
				<div class="h3 mb-2">
                    Enjoy free cloud storage
                </div>
                <div class="text-muted">
                    Enjoy a generous amount of free cloud storage—free forever!
                </div>
			</div>
		</div>
		<div class="d-flex flex-row">
			<div>
				<span class="material-icons-outlined text-primary">check_circle</span>
			</div>
			<div class="ml-3">
				<div class="h3 mb-2">
                    Sync everywhere
                </div>
                <div class="text-muted">
                    Sync your CSS to any device where Amino is installed
                </div>
			</div>
		</div>
    </div>
    <div class="p-5 auth-form-container rounded">
        <div class="text-center">
            <GoogleLoginButton>Sign Up With Google</GoogleLoginButton>
        </div>
        <div class="text-divider mb-4">
            <span class="small px-3">or</span>
        </div>
        <b-form @submit.prevent="onSubmit">
            <b-form-group id="input-group-1">
                <b-form-input id="input-1" type="email" v-model="email" placeholder="Email Address" required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4">
                <b-input pill type="password" id="text-password" v-model="password" placeholder="Password" required></b-input>
            </b-form-group>
            <b-form-group id="input-group-4">
                <vue-recaptcha sitekey="6LePeF8aAAAAAK9mQxHO-GDt1PtjbBTG4Y6xb3Qa" size="invisible" ref="captcha" @verify="onVerify(email, password, first_name, last_name)" @error="onError" :loadRecaptchaScript="true"></vue-recaptcha>
                <b-overlay :show="verifying" opacity="0.6" spinner-small spinner-variant="primary">
                    <b-button type="submit" variant="primary" class="w-100">Sign up</b-button>
                </b-overlay>
            </b-form-group>
        </b-form>
        <div class="small p-2 text-danger text-center mb-1" v-if="error">{{ error }}</div>
        <hr class="my-4">
        <div class="small text-center font-weight-bold">
            <div class="mb-1">Already have an account?</div>
            <router-link class="text-primary" :to="signInLink">Sign in here</router-link>
        </div>
    </div>
</div>
</template>

<script>
const VueRecaptcha = () => import('vue-recaptcha');
const GoogleLoginButton = () => import('@/components/GoogleLoginButton');
export default {
    name: 'CreateAccount',
    components: {
        GoogleLoginButton,
        VueRecaptcha
    },
    data() {
        return {
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            error: null,
            verifying: false
        };
    },
    computed: {
        redirect() {
            if (typeof this.$route.query.redirect !== "undefined") {
                return this.$route.query.redirect;
            }

            return false;
        },
        signInLink() {
            if (this.redirect) {
                return '/sign-in?redirect=' + this.redirect;
            }
            return '/sign-in';
        }
    },
    methods: {
        onSubmit() {
            this.verifying = true;
            this.$refs.captcha.execute();
        },
        onError(err) {
            console.log('CAPTCHA ERROR:', err);
        },
        onVerify(email, password, first_name, last_name) {
            console.log(email, password);
            this.error = null;
            const {
                User
            } = this.$FeathersVuex.api
            new User({
                    email,
                    password,
                    first_name,
                    last_name
                })
                .save()
                .then(() => {
                    this.$store.dispatch('auth/authenticate', {
                            strategy: 'local',
                            email,
                            password
                        })
                        .then(({
                            accessToken
                        }) => {
                            this.removeGoogleOneTap();
                            this.loginExtension(accessToken);
                            if (this.redirect) {
                                this.$router.push('/' + this.redirect);
                            } else {
                                this.$router.push('/library');
                            }
                        });
                })
                // Just use the returned error instead of mapping it from the store.
                .catch(err => {
                    console.log(err);
                    // Convert the error to a plain object and add a message.
                    let type = typeof err.data !== "undefined" ? err.data.code : 0;
                    err = Object.assign({}, err);
                    err.message =
                        type === 11000 ?
                        'That email address is unavailable.' :
                        'An error prevented signup.'
                    this.error = err.message;
                    this.verifying = false;
                    this.$refs.captcha.reset();
                });
        }
    }
}
</script>

<style lang="scss">
.auth-highlights-container {
	// width: 50%;
	max-width: 550px;
	padding-right: 2rem;
}
</style>
