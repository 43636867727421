<template>
<div class="d-flex flex-column h-100">
	<NavbarMain></NavbarMain>
	<div class="d-flex align-items-center justify-content-center flex-grow-1">
		<div class="w-100 auth-container">
			<router-view></router-view>
			<!-- <transition name="component-fade" mode="out-in">

			</transition> -->
		</div>
	</div>
	<footer class="w-100 auth-footer text-center text-muted px-3 pb-5">
		<div class="mb-2">By using Amino you agree to the <router-link to="/privacy">Privacy Policy</router-link> and <router-link to="/terms">Terms of Service</router-link>.</div>
		<div>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</div>
	</footer>
</div>
</template>

<script>
import NavbarMain from '../../components/NavbarMain';
export default {
	name: 'Auth',
	components: {
		NavbarMain
	}
}
</script>

<style lang="scss">
.auth-container {
	max-width: 1200px;
}
.auth-form-container {
    width: 400px;
	border: 1px solid var(--color-700);
	@media (max-width: 767px) {
		width: 100%;
	}
}
.auth-footer {
	font-size: 11px;
	a {
		color: inherit;
		text-decoration: underline;
	}
}
</style>
