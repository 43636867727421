<template>
	<div class="d-flex flex-column align-items-center justify-content-center h-100">
		<template v-if="bar.progress < 150">
			<div class="h2 mb-3">Verifying your email</div>
			<div class="text-muted small mb-5">
				{{ key }}
			</div>
			<b-progress :value="bar.progress" variant="primary"></b-progress>
		</template>
		<template v-else>
			<div class="h2">Your email has been verified!</div>
		</template>
	</div>
</template>

<script>
export default {
	name: 'CreateAccount',
	data() {
		return {
			key: false,
			bar: {
				progress: 0,
				timer: false
			},
			verified: false
		};
	},
	computed: {
		user () {
			return this.$store.getters['auth/user'];
		}
	},
	async mounted () {
		this.key = this.$route.params.key;
		this.timer = setInterval(() => {
			this.bar.progress = this.bar.progress + 5;
			if (this.bar.progress >= 150) {
				clearInterval(this.timer);
				if (this.verified) {
					this.user.isVerified = true;
					this.$router.push('/');
				}
			}
		}, 90);
		this.$store.dispatch('verify-account/create', { key: this.key, verificationKey: this.user.verificationKey }).then(({ verified }) => {
			if (verified && this.bar.progress >= 150) {
				clearInterval(this.timer);
				this.user.isVerified = true;
				this.$router.push('/');
			} else {
				this.verified = true;
			}
		});
	}
}
</script>

<style lang="scss" scoped>

</style>
