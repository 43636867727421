<template>
	<div class="d-flex flex-row justify-content-center px-5">
		<div class="loader full-bleed" v-if="flag === 'oauth-success'">
			<div class="spinner"></div>
		</div>
		<div class="p-5 auth-form-container rounded">
			<div class="text-center">
				<div v-if="!verify">
					<GoogleLoginButton>Sign In With Google</GoogleLoginButton>
					<div class="text-divider mb-4">
						<span class="small px-3">or</span>
					</div>
				</div>
				<div v-else>
					<div class="h3 mb-3">Verify your account</div>
					<div class="mb-4 text-muted">This Google account is associated with an existing Amino account. Please enter the password of the existing account to associate it with this Google account.</div>
				</div>
			</div>
			<div>
				<b-form method="post" @submit.prevent="onSubmit">
					<b-form-group id="input-group-1">
						<b-input v-model="email" id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Email" required></b-input>
					</b-form-group>

					<b-form-group id="input-group-1">
						<b-input id="inline-form-input-username" v-model="password" placeholder="Password" type="password" required></b-input>
					</b-form-group>

					<b-form-group id="input-group-4">
						<vue-recaptcha sitekey="6LePeF8aAAAAAK9mQxHO-GDt1PtjbBTG4Y6xb3Qa" size="invisible" ref="captcha" @verify="onVerify(email, password)" @error="captchaError('Captcha Error')" @expired="captchaError('Expired Captcha')" :loadRecaptchaScript="true"></vue-recaptcha>
						<b-overlay :show="verifying" opacity="0.6" spinner-small spinner-variant="primary">
							<b-button class="w-100" variant="primary" type="submit">Sign in</b-button>
						</b-overlay>
					</b-form-group>
				</b-form>
				<div class="small rounded bg-dark my-3 p-2 text-center" v-if="error">{{ error }}</div>
				<div class="small text-center"><router-link to="/forgot-password" class="text-muted">Forgot your password</router-link></div>
			</div>
			<hr class="my-4">
			<div class="small text-center font-weight-bold">
				<div class="mb-1">Don't have an account?</div>
				<router-link class="text-primary" :to="createAccountLink">Create one for free</router-link>
			</div>
		</div>
	</div>
</template>

<script>
const VueRecaptcha = () => import('vue-recaptcha');
const GoogleLoginButton = () => import('@/components/GoogleLoginButton');
export default {
	name: 'SignIn',
	components: {
		GoogleLoginButton,
		VueRecaptcha
	},
	data() {
		return {
			error: '',
			email: '',
			password: '',
			response: '',
			loginWithOauth: true,
			verifying: false
		}
	},
	computed: {
		User () {
			const {
				User
			} = this.$FeathersVuex.api;
			return User;
		},
		user () {
			return this.$store.getters["auth/user"];
		},
		redirect () {
			return this.$route.query.redirect || false;
		},
		createAccountLink () {
			if (this.redirect) {
				return '/create-account?redirect=' + this.redirect;
			}
			return '/create-account';
		},
		flag () {
			return this.$route.params.flag;
		},
		verify () {
			return (this.$route.params.flag === 'oauth-verify');
		}
	},
	methods: {
		onSubmit() {
			this.verifying = true;
			this.$refs.captcha.execute();
		},
		onVerify(email, password) {
			this.error = false;
			this.$store
				.dispatch('auth/authenticate', {
					strategy: 'local',
					email,
					password
				})
				.then(async ({ accessToken }) => {
					this.loginExtension(accessToken);
					if (this.flag === 'oauth-verify') {
						await this.verifyOauth();
					}
					if (this.redirect) {
						this.$router.push('/' + this.redirect);
					} else {
						this.$router.push('/library');
					}
				})
				// Just use the returned error instead of mapping it from the store.
				.catch(err => {
					this.error = err.message;
					this.verifying = false;
					this.$refs.captcha.reset();
				})
		},
		async verifyOauth () {
			if (this.user.oauthVerified === false) {
				this.user.oauthVerified = true;
				await this.user.save();
			}
		},
		captchaError (message) {
			this.verifying = false;
			this.$refs.captcha.reset();
			this.error = message;
		}
	},
	mounted () {
		if (this.flag === 'oauth-success') {
			const { accessToken } = this.$route.query;
			this.$store.dispatch('auth/authenticate', {
				strategy: 'jwt',
				accessToken
			}).then(() => {
				this.loginExtension(accessToken);
				if (this.redirect) {
					this.$router.push({ path: '/' + this.redirect });
				} else {
					this.$router.push('/library');
				}
			})
			.catch(err => {
				console.log(err);
			});
		}
		if (this.flag === 'oauth-error') {
			this.error = this.$route.query.error;
		}
	}
}
</script>
