<template>
	<div class="d-flex justify-content-center px-5">
		<div class="p-5 auth-form-container rounded">
			<div v-if="sent">
				<div class="text-center">
					<h1 class="h2 mb-3">Forgot your password?</h1>
					<div class="text-muted">An email has been sent to the email provided if an associated account exists.</div>
				</div>
			</div>
			<div v-else-if="!key">
				<div class="text-center">
					<h1 class="h2 mb-3">Forgot your password?</h1>
					<div class="text-muted mb-4">Enter your email address and we’ll send you a reset password link.</div>
				</div>
				<b-form @submit.prevent="onSubmit(email)">
					<b-form-group id="input-group-1">
						<b-input v-model="email" id="inline-form-input-name" class="mb-2 mr-sm-2 mb-sm-0" placeholder="Email" required></b-input>
					</b-form-group>

					<b-form-group id="input-group-2">
						<b-button variant="primary" type="submit" class="w-100" v-if="!loading" :disabled="!email.length">Send Reset Link</b-button>
						<b-skeleton type="button" variant="primary" v-else></b-skeleton>
					</b-form-group>
				</b-form>
				<div class="mt-4 small text-center"><router-link to="/sign-in" class="text-muted">Return to sign in</router-link></div>
			</div>
			<div v-else-if="verify">
				<div class="text-center">
					<h1 class="h3 mb-4">Set a new password</h1>
				</div>
				<b-form @submit.prevent="updatePassword(password)">
					<div class="mb-4" role="group">
						<b-form-input type="password" v-model="password" trim placeholder="New Password" required></b-form-input>
						<b-form-invalid-feedback>
							Minimum of 8 characters
						</b-form-invalid-feedback>
					</div>
					<div class="mb-4" role="group">
						<b-form-input type="password" v-model="confirmPassword" trim placeholder="Confirm Password" required></b-form-input>
						<b-form-invalid-feedback>
							Minimum of 8 characters
						</b-form-invalid-feedback>
					</div>
					<b-button class="w-100" variant="primary" size="sm" type="submit" :disabled="!passwordsMatch" v-if="!loading">Reset password</b-button>
					<b-skeleton type="button" variant="primary" v-else></b-skeleton>
				</b-form>
			</div>
			<div v-else-if="key">
				<div class="text-center">
					<h1 class="h3 mb-4">That link is no longer valid.</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ForgotPassword',
		data () {
			return {
				sent: false,
				email: '',
				password: '',
				confirmPassword: '',
				verify: false,
				loading: false
			};
		},
		computed: {
			ForgotPassword () {
				return this.$FeathersVuex.api.ForgotPassword;
			},
			key () {
				return this.$route.params.key || false;
			},
			passwordsMatch () {
				return this.password.length > 0 && this.password === this.confirmPassword;
			}
		},
		methods: {
			async onSubmit(email) {
				this.loading = true;
				const forgotPassword = new this.ForgotPassword({ email });
				await forgotPassword.create();
				this.sent = true;
				this.loading = false;
			},
			async updatePassword (password) {
				this.loading = true;
				this.verify.password = password;
				await this.verify.update();
				this.makeToast('Password Updated!');
				this.$router.push('/sign-in');
			}
		},
		mounted () {
			if (this.key) {
				this.ForgotPassword.find({ query: { key: this.key } }).then(res => {
					if (res.total > 0) {
						this.verify = res.data[0];
					}
				});
			}
		}
	}
</script>

<style lang="scss">
</style>
