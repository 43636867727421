<template>
	<div class="loader">
		<div class="spinner"></div>
	</div>
</template>

<script>
export default {
	name: 'SignOut',
	async mounted () {
		try {
			await this.$store.dispatch('auth/logout');
		} catch (e) {
			console.log('LOGOUT ISSUE:', e);
		}
		this.logoutExtension();
		this.$router.push('/sign-in');
		this.loadGoogleOneTap();
	}
}
</script>

<style lang="scss">

</style>
